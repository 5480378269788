import {getMetaTagContent} from './get-meta';

const {NODE_ENV, REACT_APP_SERVER_URI = 'http://localhost:5000'} = process.env;

let serverUri: string | null;

if (NODE_ENV === 'development') {
  serverUri = REACT_APP_SERVER_URI;
} else {
  serverUri = getMetaTagContent('app:server-uri');
}

const isDev = (function isDev(): boolean {
  // Detect ReactNative - https://github.com/facebook/react-native/blob/d01ab66b47a173a62eef6261e2415f0619fefcbb/Libraries/Core/InitializeCore.js#L148
  if (navigator.platform === 'ReactNative') {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
    // @ts-ignore
    return __DEV__; // eslint-disable-line no-undef
  } else {
    // Create React app Style
    const {NODE_ENV} = process.env;
    return NODE_ENV !== 'production';
  }
})();

/*
 * Retrieve the server uri from the app:server-uri meta tag when in prod mode
 * in dev mode use the REACT_APP_SERVER_URI variable
 */
export class ConfigService {
  static get serverUri(): string | null {
    if (serverUri != null) {
      // strip trailing slash
      return serverUri.replace(/\/$/, '');
    } else {
      return serverUri;
    }
  }

  /**
   * Should be one of production,development,testing,staging etc.
   */
  static get stage(): string {
    if (NODE_ENV === 'development') {
      return 'development';
    } else {
      return getMetaTagContent('app:stage') || 'production';
    }
  }

  static get isDev(): boolean {
    return isDev;
  }
}
